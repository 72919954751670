// ignore-string-externalization
import React from 'react';
import { ModuleType } from '../types';
import { ContentfulAsset } from '../../Contentful';
import { HeroBodyModule as FMHeroBodyModule } from '@spotify-internal/frodor-modules';
import { UnstyledLink } from '../../ui';

export type HeroBodyModuleType = ModuleType & {
  headline?: string;
  bodyText?: string;
  image?: ContentfulAsset;
  ctaLink?: string;
  ctaText?: string;
  backgroundColor?: string;
  textColor?: string;
  layout?: string;
  btnComponent?: React.FC;
};

export const HeroBodyModule = React.forwardRef<
  React.MutableRefObject<ModuleType>,
  HeroBodyModuleType
>((props, ref) => {
  const LinkWithTracking: React.FC<any> = linkProps => {
    return (
      <UnstyledLink
        {...linkProps}
        external={linkProps.target === '_blank'}
        track={['module-heroBody', `CTA Link - ${props.ctaText}`]}
      />
    );
  };
  return (
    <FMHeroBodyModule {...props} ref={ref} btnComponent={LinkWithTracking} />
  );
});
