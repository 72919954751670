import React from 'react';
import { ModuleType } from '../types';
import { HeroBodyCarousel as FMHeroBodyCarousel } from '@spotify-internal/frodor-modules';
import { HeroBodyModuleType } from '../HeroBodyModule';
import { UnstyledLink } from '../../ui';

type HeroBodyCarouselType = ModuleType & {
  carouselSlides: HeroBodyModuleType[];
  onShowSlide?: (slide: ModuleType, index: number) => void;
};

export const HeroBodyCarousel = React.forwardRef<
  React.MutableRefObject<ModuleType>,
  HeroBodyCarouselType
>(({ contentType, carouselSlides = [], onShowSlide = () => {} }, ref) => {
  carouselSlides.map(module => {
    module.btnComponent = (linkProps: any) => (
      <UnstyledLink
        {...linkProps}
        track={['module-heroBody', `CTA Link - ${module.ctaText}`]}
      />
    );
  });

  return (
    <FMHeroBodyCarousel
      contentType={contentType}
      carouselSlides={carouselSlides}
      onShowSlide={onShowSlide}
      ref={ref}
    />
  );
});
