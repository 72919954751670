// ignore-string-externalization
import React from 'react';
import { ModuleType } from '../types';
import { AccordionModule as FMAccordionModule } from '@spotify-internal/frodor-modules';
import { UnstyledLink } from '../../ui';
import { Colors } from '../../ui/colorsType';

type TitleAndContentType = {
  title: string;
  content: string;
};

export type AccordionModuleType = ModuleType & {
  headline?: string;
  items: Array<TitleAndContentType>;
  ctaLink?: string;
  ctaText?: string;
  btnComponent?: React.FC;
  themeName?: string;
  colors?: Colors;
};

export const AccordionModule = React.forwardRef<
  React.MutableRefObject<ModuleType>,
  AccordionModuleType
>((props, ref) => {
  const LinkWithTracking: React.FC<any> = linkProps => {
    return (
      <UnstyledLink
        {...linkProps}
        track={['module-faqAccordion', `CTA Link - ${props.ctaText}`]}
      />
    );
  };
  return (
    <FMAccordionModule {...props} ref={ref} btnComponent={LinkWithTracking} />
  );
});
