import { screenSmMax } from '@spotify-internal/encore-web';
import styled from 'styled-components';

export const HeaderFiller = styled.div<{ backgroundColor: string }>`
  background-color: ${({ backgroundColor }) => backgroundColor};
  height: 72px;
  @media (max-width: ${screenSmMax}) {
    height: 56px;
  }
`;
