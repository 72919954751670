import React from 'react';
import { VideoModule as FMVideoModule } from '@spotify-internal/frodor-modules';
import { ModuleType } from '../types';
import { UnstyledLink } from '../../ui';
import { useT } from '../../../../features/src/i18n';
import styled from 'styled-components';
import { screenSmMax } from '@spotify-internal/encore-web';
import { HeaderFiller } from '../../Layout/HeaderFiller';

export type VideoModuleType = ModuleType & {
  supertext: string;
  headline: string;
  subtext?: string;
  useCustomCta?: boolean;
  ctaLink?: string;
  ctaText?: string;
  videoUrl: string;
  btnComponent?: React.FC;
  videoControlColors: { backgroundColor: string; textColor: string };
};

const Wrapper = styled.div<{ isHeader?: boolean }>`
  // top of video should be 100px from top of screen
  // with headerfiller we have 48px + 72px, so need to subtract 20
  margin-top: ${({ isHeader }) => (isHeader ? '-20px' : '')};
  @media (max-width: ${screenSmMax}) {
    // top of video should be 72px from top of screen
    // with headerfiller we have 24px + 56px, so need to subtract 8
    margin-top: ${({ isHeader }) => (isHeader ? '-8px' : '')};
  }
`;

export const VideoModule = React.forwardRef<
  React.MutableRefObject<ModuleType>,
  VideoModuleType
>((props, ref) => {
  const t = useT();
  const defaultCtaText = t(
    'FRODOR_VIDEO_MODULE_BACK_TO_VIDEOS',
    'Back to videos',
    'A link that when clicked will take the user back to All Videos',
  );
  const defaultCtaLink = '/videos';

  const LinkWithTracking: React.FC<any> = linkProps => {
    return (
      <UnstyledLink
        {...linkProps}
        track={[
          'module-video',
          `CTA Link - ${props.ctaText || defaultCtaText}`,
        ]}
      />
    );
  };

  return (
    <Wrapper isHeader={props.isHeader}>
      {props.isHeader && (
        <HeaderFiller
          backgroundColor={props.colors?.backgroundColor || '#000000'}
        />
      )}
      <FMVideoModule
        {...props}
        ctaText={props.useCustomCta ? props.ctaText : defaultCtaText}
        ctaLink={props.useCustomCta ? props.ctaLink : defaultCtaLink}
        btnComponent={LinkWithTracking}
        // @ts-ignore
        ref={ref}
      />
    </Wrapper>
  );
});
